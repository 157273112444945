/*!
 This should not be removed.
 */

@import "components/no_content";

/*
----------------------------------------------
	1. div.tmkm-amazon-view
----------------------------------------------
*/
.tmkm-amazon {
	&-view {
		clear: both;
		margin: 3em auto;
		padding: 1em;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		font-size: 1rem;

		p a {
			&:link, &:visited {
				text-decoration: none;
			}

			&:hover {
				text-decoration: underline;;
			}
		}
	}


	&-row {
		display: flex;
		margin-bottom: 1em;
		justify-content: flex-start;
	}

	&-label {
		display: inline-block;
		width: 8em;
		font-weight: bold;
		flex-shrink: 0;
	}

	&-title {
		font-size: 1.2em;
		line-height: 1.2;
		font-weight: bold;
	}

	&-category {
		display: inline-block;
		margin-left: 1em;
		background-color: #000;
		border-radius: 3px;
		color: #fff;
		text-decoration: none;
		padding: 2px 5px;
	}

	&-img{}

	&-image {
		float: right;
		margin: 0 0 1em 1em;
		padding: 0.5em;
		border: solid 1px #ddd;
		background-color: #fff;
		max-width: 200px;
		max-height: 250px;
		width: auto;
		height: auto;
	}

	p {

		em {
			font-weight: bold;
		}

		&.description {
			font-size: 0.85em;
			line-height: 1.2;
		}

		&.additional-description {
			font-size: 1em;
			line-height: 1.4;
			margin: 1em 0;
		}
	}

	&-vendor {
		clear: both;
		font-size: 0.85em;
		text-align: right;

		a {
			color: #999;
		}
	}

	&-actions {
		margin-top: 2em;
	}

	&-btn {
		display: inline-block;
		padding: 0.5em 1em;
		border-style: solid;
		border-width: 1px;
		border-radius: 3px;
		text-decoration: none !important;
		font-size: 1.2em;
		font-weight: bold;
		transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out;

		& + & {
			margin-left: 0.5em;
		}

		@each $brand, $color in (
						( amazon, #FF9900 ),
						( dmm, #EF2737 ),
						( phg, #000 ),
		) {
			&-#{$brand} {
				color: $color;
				border-color: $color;
				background-color: transparent;

				&:hover,
				&:active {
					color: #fff;
					background-color: $color;
				}
			}
		}
	}



	@media only screen and ( max-width: 767px ) {
		&-image {
			float: none;
			display: block;
			margin: 2em auto;
		}
		&-actions {
			text-align: center;
			clear: both;
		}

		&-btn {
			display: block;
			text-align: center;
		}
		&-vendor {
			text-align: center;
		}
	}
}

.hamazon-block-no-content {

}
